
import { defineComponent } from 'vue';
import { cloneDeep } from 'lodash';
import DateRange from '~/components/WatFocus/DateRange.vue';
import UserSlot from '~/components/WatFocus/UserSlot.vue';
import moment from 'moment-timezone';
import { mapGetters } from 'vuex';
export default defineComponent({
name : 'WatFocusCalendar',
components:{
  DateRange,
  UserSlot
},
props:{
  ranges:{
    type: Array,
    default: () => []
  },
  slots:{
    type:Array,
    default: () => []
  },
  sessionMinutes:{
    type:Number,
    default:30
  },
  isConfig:{
    type: Boolean,
    default: false
  },
  startFromCurrentDate:{
    type: Boolean, 
    default: false
  },
  startFromFirstRange:{
    type: Boolean,
    default: true
  },
  showColors:{
    type: Boolean,
    default: false
  }
},
computed:{
  ...mapGetters([
    'clientCountry'
  ])
},
data(){
  return{
    calendarDates:[{
      date:new Date(),
      ranges:[{from:"00:00",to:"00:00", id:0}],
      disabled: false
    }],
    availableHours:new Array()
  }
},
watch:{
  ranges(newValue: any){
    this.createRanges()
  }
},
methods:{
  calendarBack(){
    const element: any = document.getElementById("WatfocusCalendarDates")
    element.scrollLeft -=100
  },
  calendarNext(){
    const element: any = document.getElementById("WatfocusCalendarDates")
    element.scrollLeft +=100
  },
  generateCalendar(){
    this.calendarDates = []
    const firstRange: any = this.ranges[0]
    let startDate = new Date();
    if(firstRange){
      let daysBetween = this.getDaysBetweenDates(startDate, new Date(firstRange.dateFrom))
      if(daysBetween > 0 && !this.startFromFirstRange) daysBetween = 2
      startDate.setDate(startDate.getDate() + daysBetween);
    }
    else{
      const daysToStart = this.startFromCurrentDate ? 0 : 2
      startDate.setDate(startDate.getDate() + daysToStart);
    }
    for (let i = 0; i <= 30; i++) {
      let date = this.getDate(startDate, i)
      const calendarDay = {
        date: date,
        ranges:[],
        disabled: this.isSunday(date)
      }
      this.calendarDates.push(calendarDay);
    }
  },
  isSunday(date: Date){
    return date.getDay() === 0;
  },
  getCalendarDay(date: Date){
    let vm = this
    return this.calendarDates.filter(function(cd){
      return vm.sameDayDate(cd.date, date)
    })[0]
  },
  sameDayDate(date1: Date, date2: Date){
    return date1.getFullYear() === date2.getFullYear() &&
          date1.getMonth() === date2.getMonth() &&
          date1.getDate() === date2.getDate();
  },
  getDate(date: any, days: any){
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  },
  addRange(calendarDay: any, hour: any, id:any, event: any){
    if(calendarDay.disabled ||(this.isConfig && event != null)) return
    const hourAbsolut = this.getRangeAbsolutValue(hour) - 1
    const hourMinutes = this.getRangeMinuteValue(hour)
    const minutesFrom = hourMinutes < 10 ? "0" + hourMinutes : hourMinutes
    const hourFrom = hourAbsolut < 10 ? "0" + hourAbsolut : hourAbsolut
    if(hourAbsolut == 7) return
    const currentDate = new Date()
    const calendarDayDate = calendarDay.date
    calendarDayDate.setHours(hourAbsolut)
    calendarDayDate.setMinutes(hourMinutes)
    //if(currentDate > calendarDayDate) return
    const exists = calendarDay.ranges.some((range: { from: string; to: any; }) => range.from === hourFrom + ":" + minutesFrom && range.to === hour);
    if (!exists) calendarDay.ranges.push({from:hourFrom+":"+minutesFrom,to:hour, id:id})
  },
  formatDateTo(dateTo: any){
    let hourPre = ""
    if (this.getRangeAbsolutValue(dateTo) < 10) hourPre = "0"
    return hourPre + this.getRangeAbsolutValue(dateTo) + ":" + dateTo.split(":")[1]
  },
  deleteRange(calendarDay: any, hour: any){
    for(let index in calendarDay.ranges) {
      if(calendarDay.ranges[index].from == hour){
        calendarDay.ranges.splice(index,1)
        break;
      }
    }
  },
  showDateRange(calendarDay: any, hour: any){
    if(this.isConfig) return false
    return this.getRange(calendarDay, hour) != null ? true : false
  },
  showSlotRange(calendarDay: any, hour: any){
    if(!this.isConfig) return false
    const x = this.getRange(calendarDay, hour) != null ? true : false
    return x
  },
  getRangeText(calendarDay: any, hour: any){
    let range = this.getRange(calendarDay, hour)
    if(range == null) return ''
    return range.from + "-" + range.to
  },
  getRangeTextConfig(calendarDay: any, hour: any){
    let range = this.getRange(calendarDay, hour)
    if(range == null) return ''
    return moment(calendarDay.date).format("DD.MM | ") + range.from + " - " + range.to
  },
  formatDate(date: any, format: any){
    moment.locale(this.clientCountry)
    return moment(date).format(format);
  },
  findSlotsInRange(calendarDay: any, hour: any){
    const range = this.getRange(calendarDay, hour)
    if(range == null) return []
    const slots = this.getSlots(range)
    return slots
  },
  getSlots(range: any){
    let slotList = []
    for(let slot of this.slots){
      const s: any = slot
      if(this.isDateTimeInRange(range.from, range.to, s.dateFrom) && s.rangeId == range.id) slotList.push(s)
    }
    return slotList
  },
  isDateTimeInRange(from: any, to: any, date: any) {
    let [fromHours, fromMins] = from.split(":").map(Number);
    let [toHOurs, toMins] = to.split(":").map(Number);
    let fromDate = new Date();
    fromDate.setHours(fromHours, fromMins, 0, 0);
    let toDate = new Date();
    toDate.setHours(toHOurs, toMins, 0, 0);
    let dateObject = new Date(date);
    let dateToCheck = new Date();
    dateToCheck.setHours(dateObject.getHours(), dateObject.getMinutes(), 0, 0);
    return dateToCheck >= fromDate && dateToCheck <= toDate;
  },
  getRange(calendarDay: any, hour: any){
    let requestRange = null
    for(let range of calendarDay.ranges){
      const rangeFrom = this.getRangeAbsolutValue(range.from)
      const hourAbsolut = this.getRangeAbsolutValue(hour)
      if(rangeFrom == hourAbsolut) {
        requestRange = range
        break;
      }
    }
    return requestRange
  },
  getRangeAbsolutValue(value: any){
    return Number(value.split(":")[0])
  },
  getRangeMinuteValue(value: any){
    return Number(value.split(":")[1])
  },
  getConfirmedNum(slot: any){
    if(slot.testers == undefined || slot.testers == null) return 0
    return slot.testers.filter((t: any) => !t.hasConfirmedOtherSlot).length
  },
  getSlotStyle(slot: any, calendarDay: any, hour: any){
    const range = this.getRange(calendarDay, hour)
    const fromHour = this.getRangeAbsolutValue(range.from)
    const fromMins = this.getRangeMinuteValue(range.from)
    const slotHour = Number(moment(slot.dateFrom).format("HH"))
    const slotMinutes = Number(moment(slot.dateFrom).format("mm"))
    const result = (slotHour - fromHour) + ((slotMinutes - fromMins) / 60)
    const style = {
      marginTop: ((result * 52) + 55) + "px",
      height:44* (this.sessionMinutes / 60) + "px"
    }
    return style
  },
  getRangeStyle(calendarDay: any, hour: any){
    const HEIGHT = 52
    const range = this.getRange(calendarDay, hour)
    if(range == null) {
      return {
        'height':HEIGHT+"px",
        'marginTop':0+"px"
      }
    }
    const multiplicator = this.getRangeAbsolutValue(range.to) - this.getRangeAbsolutValue(range.from) 
    const style = {
      'height':HEIGHT * multiplicator + "px",
      'marginTop':HEIGHT + "px"
    }
    return style
  },
  getRangeMaxLimit(calendarDay: any, hour: any){
    const HEIGHT = 52
    const absoluteHour = this.getRangeAbsolutValue(hour) < 10 ? "0" + this.getRangeAbsolutValue(hour) + ":00" : this.getRangeAbsolutValue(hour) + ":00"
    const availableHours = cloneDeep(this.availableHours)
    const nextHours = availableHours.splice(availableHours.indexOf(absoluteHour) + 1)
    let limit = nextHours.length * HEIGHT
    for(let nh of nextHours){
      let range = this.getRange(calendarDay, nh)
      if(range != null){
        const multiplicator = this.getRangeAbsolutValue(range.from) - this.getRangeAbsolutValue(hour) 
        limit =  multiplicator * HEIGHT - HEIGHT
        break;
      }
    }
    return limit
  },
  updatedRange(height: any, calendarDay: any, hour: any){
    const HEIGHT = 52
    const hoursBetween = Math.ceil(Math.abs(height / HEIGHT))
    const range = this.getRange(calendarDay, hour)
    if(range == null) return
    const rangeFromHours = this.getRangeAbsolutValue(hour) + hoursBetween
    const rangeFromMinutes = this.getRangeMinuteValue(hour)
    const minutes = rangeFromMinutes < 10 ? "0"+rangeFromMinutes : rangeFromMinutes
    const newRangeTo = rangeFromHours + ":" + minutes
    if (range.from == this.formatDateTo(newRangeTo)) return
    range.to = this.formatDateTo(newRangeTo)
  },
  getRangesFormated(){
    let ranges: any = []
    for(let cd of this.calendarDates){
      for(let r of cd.ranges){
        ranges.push({
          dateFrom: this.createDate(cd.date, r.from),
          dateTo: this.createDate(cd.date, r.to),
          id: r.id
        })
      }
    }
    return ranges;
  },
  createDate(date: any, hourString: any){
    let [hours, minutes] = hourString.split(':').map(Number);
    let newDate = new Date(date);
    newDate.setHours(hours);
    newDate.setMinutes(minutes);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    return newDate.toISOString();
  },
  createRangeID(){
    let id = 0
    for(let cd of this.calendarDates) {
      for(let r of cd.ranges){
        if(r.id != undefined && r.id > id) id = r.id
      }
    }
    return id + 1
  },
  getHoursBetweenRanges(range: any){
    const dateFrom = new Date(range.dateFrom)
    const dateTo = new Date(range.dateTo)
    const diff = dateTo.getTime() - dateFrom.getTime()
    const diffInHours = diff / (1000 * 60 * 60);
    return diffInHours;
  },
  getDaysBetweenDates(date1: Date, date2: Date){
    const timeDifference = date2.getTime() - date1.getTime();
    const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
    return Math.floor(dayDifference)
  },
  createRangeHeight(range: any){
    return this.getHoursBetweenRanges(range) * 52
  },
  createRanges(){
    const ranges: any = this.ranges
    for(let r of ranges) {
      const calendarDay: any = this.getCalendarDay(new Date(r.dateFrom))
      let hourFromPlus1 = moment(r.dateFrom).add(1,'hour').format("HH:mm")
      let hourFrom = moment(r.dateFrom).format("HH:mm")
      this.addRange(calendarDay, hourFromPlus1, r.id, null)
      const height = this.createRangeHeight(r)
      this.updatedRange(height, calendarDay, hourFrom)
    }
  }
},
mounted(){
  this.generateCalendar()
  this.availableHours = [
  "08:00","09:00","10:00","11:00","12:00","13:00","14:00","15:00","16:00","17:00","18:00","19:00","20:00"
  ]
  this.createRanges()
}
})
