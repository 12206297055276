import { defineComponent, getCurrentInstance } from 'vue';
import Button from '@/components/Button.vue';
import moment from 'moment-timezone';
export default defineComponent({
    name: 'DateRange',
    components: {
        Button: Button
    },
    data: function () {
        var _a;
        return {
            _uid: (_a = getCurrentInstance()) === null || _a === void 0 ? void 0 : _a.uid,
            isResizing: false,
            newHeight: 0
        };
    },
    props: {
        height: String,
        marginTop: String,
        testersWanted: Number,
        confirmedNum: Number,
        slot: Object,
        hideText: {
            type: Boolean,
            default: false
        },
        showColors: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        text: function () {
            if (this.hideText)
                return "";
            return this.confirmedNum + " / " + this.testersWanted + " personas";
        },
        date: function () {
            var _a, _b;
            return moment((_a = this.slot) === null || _a === void 0 ? void 0 : _a.dateFrom).format('DD.MM | HH:mm') + ' - ' + moment((_b = this.slot) === null || _b === void 0 ? void 0 : _b.dateTo).format("HH:mm");
        }
    },
    methods: {},
    mounted: function () {
    }
});
